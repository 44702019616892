import create from 'zustand';

export const NOTIFICATION_TYPES = {
  Success: 'success',
  Error: 'error'
};

export const useUIStore = create((set) => ({
  notification: null,
  setNotification: (notification) => set(() => ({ notification }))
}));
