import { AuthStatus } from 'services/authService';

export const NAVIGATION_ROUTES = {
  Home: '/',
  Create: '/create',
  NewTalent: '/new-talent',
  Talents: '/talents',
  Workshops: '/workshops',
  Careers: '/careers',
  Settings: '/settings',
  KnowledgeBase: '/knowledge-base',
  Blog: '/blog',
  BlogPost: ({ slug }) => `/blog/${slug}`,
  CreateBlog: '/blog/editor/create',
  UpdateBlog: ({ slug }) => `/blog/editor/${slug}`,
  Terms: '/terms',
  Privacy: '/privacy',
  SignUp: '/auth/' + AuthStatus.SignUp,
  SignIn: '/auth/' + AuthStatus.SignIn,
  Forgot: '/forgot'
};
