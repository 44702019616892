export function identify(uid, identifyData) {
  if (!uid) return;
  try {
    analytics.identify(uid, identifyData);
  } catch (error) {
    console.log('segment identify error', error);
  }
}

export function track(key, trackData) {
  if (!key) return;
  try {
    analytics.track(key, trackData);
  } catch (error) {
    console.log('segment track error', error);
  }
}
