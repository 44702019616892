import { getAuth } from '@firebase/auth';
import { getApp, getApps, initializeApp } from 'firebase/app';
import { FieldValue, getFirestore } from 'firebase/firestore';
import { config } from 'utils/config';

const firebaseConfig = {
  apiKey: config.firebase.apiKey,
  authDomain: config.firebase.authDomain,
  projectId: config.firebase.projectId,
  storageBucket: config.firebase.storageBucket,
  messagingSenderId: config.firebase.messagingSenderId,
  appId: config.firebase.appId,
  measurementId: config.firebase.measurementId
};

if (getApps().length === 0) {
  console.log('firebase initialize app');
  initializeApp(firebaseConfig);
}

const fb = getApp();
export const auth = getAuth(fb);
export const db = getFirestore(fb);
export const serverTimestamp = FieldValue.serverTimestamp;
