export const isProd = process.env.NEXT_PUBLIC_ENV === 'production';
export const isStaging = process.env.NEXT_PUBLIC_ENV === 'staging';
export const isDev = process.env.NEXT_PUBLIC_ENV === 'development';

export const inDevEnvironment = isDev;

export const getBaseUrl = () => {
  if (isProd) return 'https://www.vidohouse.com';
  else if (isStaging) return 'https://staging-vidohouse.vercel.app';
  else return 'http://localhost:3000';
};

console.log('BASE_URL', getBaseUrl());
// export const stripeSecret = inDevEnvironment
//   ? process.env.STRIPE_SECRET_TEST
//   : process.env.STRIPE_SECRET;

// export const stripeApiKey = inDevEnvironment
//   ? process.env.NEXT_PUBLIC_STRIPE_PUBLIC_TEST
//   : process.env.NEXT_PUBLIC_STRIPE_PUBLIC;

export const devConfig = {
  sendgrid: {
    council: process.env.SENDGRID_COUNCIL
  },
  sentryInitPath: '',
  aws: {
    public: process.env.AWS_PUBLIC,
    secret: process.env.AWS_SECRET
  },
  stripe: {
    secret: process.env.STRIPE_SECRET_TEST,
    apiKey: process.env.NEXT_PUBLIC_STRIPE_PUBLIC_TEST
  },
  firebase: {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY_TEST,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN_TEST,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID_TEST,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET_TEST,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID_TEST,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID_TEST,
    clientEmail: process.env.FIREBASE_CLIENT_EMAIL_TEST,
    privateKey: process.env.FIREBASE_PRIVATE_KEY_BASE64_TEST
  }
};

export const prodConfig = {
  sendgrid: {
    council: process.env.SENDGRID_COUNCIL
  },
  sentryInitPath: '',
  aws: {
    public: process.env.AWS_PUBLIC,
    secret: process.env.AWS_SECRET
  },
  stripe: {
    stripeSecret: process.env.STRIPE_SECRET,
    stripeApiKey: process.env.NEXT_PUBLIC_STRIPE_PUBLIC
  },
  firebase: {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    clientEmail: process.env.FIREBASE_CLIENT_EMAIL,
    privateKey: process.env.FIREBASE_PRIVATE_KEY_BASE64
  }
};

export const config = inDevEnvironment ? devConfig : prodConfig;
