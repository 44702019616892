import { USER_ROLES } from 'constants/enums';
import { NAVIGATION_ROUTES } from 'constants/navigationPaths';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import * as auth from 'services/authService';
import { useUIStore } from 'store/uiStore';
import create from 'zustand';

export const useAuthStore = create((set) => ({
  authUser: USER_ROLES.Initializing,
  setAuthUser: (authUser) => set(() => ({ authUser }))
}));

export default function useAuth() {
  const listenerRef = useRef();
  const { push, query } = useRouter();
  const { redirect } = query;
  const { authUser, setAuthUser } = useAuthStore((state) => ({
    setAuthUser: state.setAuthUser,
    authUser: state.authUser
  }));

  const { setNotification } = useUIStore((state) => ({
    notification: state.notification,
    setNotification: state.setNotification
  }));

  useEffect(() => {
    if (listenerRef.current) return;
    listenerRef.current = auth.authListener((authState) => setAuthUser(authState));

    return () => listenerRef.current();
  }, []);

  const subscribeAuth = () => {
    return auth.authListener((authState) => setAuthUser(authState));
  };

  async function signOut() {
    const res = await auth.signOut();
    if (res.status === 'ok') {
      setAuthUser(null);
      push('/');
    } else console.log('error signing out', res.error);
  }
  async function forgotPassword({ email }) {
    const res = await auth.forgotPassword({ email });
    if (res.status === 'ok') {
      setNotification({
        status: 'success',
        title: `Email sent to ${email}`,
        info: 'Please check you email to reset your password and sign back in.'
      });
      push(auth.AuthStatus.SignIn);
    } else {
      setNotification({
        status: 'error',
        title: 'Something went wrong!',
        info: 'Please make sure you have given the correct email'
      });
      console.log('error signing out', res.error);
    }
  }
  async function signIn({ email, password, provider = 'email-and-password' }) {
    let res = null;
    if (provider === 'media_recorder') {
      return await auth.signIn({
        email: 'recorder@vidohouse.com',
        password: process.env.NEXT_PUBLIC_RECORDER_PWD
      });
    }

    if (provider === 'email-and-password') res = await auth.signIn({ email, password });
    if (provider === 'google') res = await auth.googleSignIn();
    if (provider === 'facebook') res = await auth.facebookSignIn();
    if (provider === 'twitter') res = await auth.twitterSignIn();
    console.log('res', { provider, res });
    if (res.status === 'ok') {
      setAuthUser(res.data);

      push(redirect || NAVIGATION_ROUTES.Workshops);
    } else {
      setNotification({
        status: 'error',
        title: 'Error Signing in!',
        info: res.error
      });
      console.log('error signing out', res.error);
    }
  }
  async function signUp({ email, password }) {
    const res = await auth.signUp({ email, password });

    if (res.status === 'ok') {
      setAuthUser(res.data);
      push(NAVIGATION_ROUTES.Workshops);
    } else {
      setNotification({
        status: 'error',
        title: 'Error Signing in!',
        info: res.error
      });
      console.log('error signing out', res.error);
    }
  }

  return {
    authUser,
    signIn,
    signOut,
    signUp,
    forgotPassword,
    subscribeAuth
  };
}
