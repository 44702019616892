import '../styles/globals.css';
import '../styles/app.css';

import Notification from 'components/atoms/Notification';
import { SEO } from 'components/orgamisms/SEO';
import useAuth from 'hooks/useAuth';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import { DefaultSeo } from 'next-seo';
import NextNprogress from 'nextjs-progressbar';
import { useEffect } from 'react';
import { useUIStore } from 'store/uiStore';
import { SWRConfig } from 'swr';
import { tw } from 'utils';
import fetcher from 'utils/fetcher';
import { identify } from 'utils/segment';

function MyApp({ Component, pageProps }) {
  const { notification } = useUIStore((state) => ({ notification: state.notification }));

  const { authUser } = useAuth();

  useEffect(() => {
    if (authUser?.uid) identify(authUser?.uid, { email: authUser?.email });
  }, [authUser]);

  return (
    <SWRConfig
      value={{
        fetcher,
        // revalidateOnFocus: false,
        // revalidateOnMount: true,
        errorRetryCount: 3
      }}>
      <div className={tw('safe-top safe-left safe-right safe-bottom')}>
        <Head>
          <meta content={'width=device-width, initial-scale=1'} name={'viewport'} />
        </Head>
        <DefaultSeo {...SEO} />
        <NextNprogress
          color={'#4f46e5'}
          startPosition={0.3}
          stopDelayMs={200}
          height={'3'}
          options={{ easing: 'ease', speed: 500, showSpinner: false }}
        />
        {notification && <Notification />}
        <Component {...pageProps} />
      </div>
    </SWRConfig>
  );
}

export default appWithTranslation(MyApp);
