/* This example requires Tailwind CSS v2.0+ */
import { Transition } from '@headlessui/react';
import { CheckCircleIcon, ExclamationIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { Fragment, useEffect, useState } from 'react';
import { useUIStore } from 'store/uiStore';
import { tw } from 'utils';

export default function Notification() {
  const [show, setShow] = useState(true);
  const { notification, setNotification } = useUIStore((state) => ({
    notification: state.notification,
    setNotification: state.setNotification
  }));

  useEffect(() => {
    setTimeout(() => {
      setNotification(null);
    }, 5000);
  }, []);

  const getIcon = () => {
    switch (notification?.status) {
      case 'success':
        return { color: 'text-green-400', Component: CheckCircleIcon };
      case 'error':
        return { color: 'text-red-400', Component: ExclamationIcon };
      default:
        return { color: 'text-green-400', Component: CheckCircleIcon };
    }
  };

  const Icon = getIcon();
  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className={tw(
          'fixed inset-0 top-14 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-50'
        )}>
        <div className={tw('w-full flex flex-col items-center space-y-4 sm:items-end')}>
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter={'transform ease-out duration-300 transition'}
            enterFrom={'translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'}
            enterTo={'translate-y-0 opacity-100 sm:translate-x-0'}
            leave={'transition ease-in duration-100'}
            leaveFrom={'opacity-100'}
            leaveTo={'opacity-0'}>
            <div
              className={tw(
                'max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden'
              )}>
              <div className={tw('p-4')}>
                <div className={tw('flex items-start')}>
                  <div className={tw('flex-shrink-0')}>
                    <Icon.Component className={tw('h-6 w-6', Icon.color)} aria-hidden="true" />
                  </div>
                  <div className={tw('ml-3 w-0 flex-1 pt-0.5')}>
                    <p className={tw('text-sm font-medium text-gray-900')}>{notification?.title}</p>
                    <p className={tw('mt-1 text-sm text-gray-500')}>{notification?.info}</p>
                  </div>
                  <div className={tw('ml-4 flex-shrink-0 flex')}>
                    <button
                      className={tw(
                        'bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      )}
                      onClick={() => {
                        setShow(false);
                      }}>
                      <span className="sr-only">Close</span>
                      <XIcon
                        className={tw('h-5 w-5')}
                        aria-hidden="true"
                        onClick={() => setNotification(null)}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
