import siteMetadata from 'constants/siteMetadata';
import { ArticleJsonLd, NextSeo } from 'next-seo';

export const SEO = {
  title: siteMetadata.title,
  description: siteMetadata.description,
  openGraph: {
    type: 'website',
    locale: siteMetadata.language,
    url: siteMetadata.siteUrl,
    title: siteMetadata.title,
    description: siteMetadata.description,
    images: [
      {
        url: `${siteMetadata.siteUrl}${siteMetadata.socialBanner}`,
        alt: siteMetadata.title,
        width: 1200,
        height: 600
      }
    ]
  },
  // twitter: {
  //   handle: siteMetadata.twitter,
  //   site: siteMetadata.twitter,
  //   cardType: 'summary_large_image'
  // },
  additionalMetaTags: [
    {
      name: 'author',
      content: siteMetadata.author
    }
  ]
};

export const PageSeo = ({ title, description, url, images = [] }) => {
  return (
    <NextSeo
      title={`${title} – ${siteMetadata.title}`}
      description={description}
      canonical={url}
      openGraph={{
        ...SEO.openGraph,
        url,
        title,
        description,
        images
      }}
    />
  );
};

export const BlogSeo = ({ title, summary, date, lastmod, url, tags, images = [] }) => {
  const publishedAt = new Date(date).toISOString();
  const modifiedAt = new Date(lastmod || date).toISOString();
  let imagesArray =
    images.length === 0
      ? [siteMetadata.socialBanner]
      : // eslint-disable-next-line unicorn/no-nested-ternary
      typeof images === 'string'
      ? [images]
      : images;

  const featuredImages = imagesArray.map((img) => {
    return {
      url: `${siteMetadata.siteUrl}${img}`,
      alt: title
    };
  });

  return (
    <>
      <NextSeo
        title={`${title} – ${siteMetadata.title}`}
        description={summary}
        canonical={url}
        openGraph={{
          type: 'article',
          article: {
            publishedTime: publishedAt,
            modifiedTime: modifiedAt,
            authors: [`${siteMetadata.siteUrl}/about`],
            tags
          },
          url,
          title,
          description: summary,
          images: featuredImages
        }}
        // additionalMetaTags={[
        //   {
        //     name: 'twitter:image',
        //     content: featuredImages[0].url
        //   }
        // ]}
      />
      <ArticleJsonLd
        authorName={siteMetadata.author}
        dateModified={modifiedAt}
        datePublished={publishedAt}
        description={summary}
        images={featuredImages}
        publisherName={siteMetadata.author}
        title={title}
        url={url}
      />
    </>
  );
};
