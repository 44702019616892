export function tw(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const classNames = tw;

export function getRandomWorkshopAsset() {
  const assetNo = Math.floor(Math.random() * 10); // between 0 - 9

  return `/assets/building_workshop/building-${assetNo}.svg`;
}

export function getSubPathFromHash(path) {
  try {
    return path.split('#')[1];
  } catch {
    return '';
  }
}

export const alphaNumericRegex = /^[\da-z]+$/i;
export const specialCharactersRegex = / [ !"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~\-]/;
export const hasSpecialCharacters = (text) => {
  return specialCharactersRegex.test(text);
};

export function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
