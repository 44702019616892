// export const fetcher = (...args) => fetch(...args).then((res) => res.json());

export default async function fetcher(url, data, method) {
  // const token = await clientDB().auth()?.currentUser?.getIdToken()

  const res = await fetch(url, {
    method: method || (data ? 'POST' : 'GET'),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json, text/plain, */*',
      'User-Agent': '*'
      // authorization: 'Bearer ' + token
    },
    body: JSON.stringify(data)
  });

  // TODO: handle unauthorized
  // TODO: handle code type returns
  return await res.json();
}
