import { setCookies } from 'cookies-next';
import { auth } from 'dbs/clientDb';
import {
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  TwitterAuthProvider
} from 'firebase/auth';

export const AuthStatus = {
  Forgot: 'forgot',
  SignIn: 'sign-in',
  SignUp: 'sign-up',
  SignOut: 'sign-out'
};

async function setCookie(authData) {
  const token = await authData?.getIdToken();
  setCookies(null, 'vh-token', token, { expires: 365 });
}
export function authListener(authStateChanged) {
  return onAuthStateChanged(auth, async (authData) => {
    await setCookie(authData);
    authStateChanged(authData);
  });
}

export async function forgotPassword({ email }) {
  try {
    await sendPasswordResetEmail(auth, email);
    return { status: 'ok' };
  } catch (error) {
    return { data: null, error: error.message, status: 'ko' };
  }
}
export async function signUp({ email, password }) {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    await setCookie(user);
    return { data: user, status: 'ok' };
  } catch (error) {
    return { data: null, error: error.message, status: 'ko' };
  }
}

export async function signIn({ email, password }) {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    await setCookie(user);
    return { data: user, status: 'ok' };
  } catch (error) {
    return { data: null, error: error.message, status: 'ko' };
  }
}
export async function googleSignIn() {
  try {
    const provider = new GoogleAuthProvider();
    const result = await signInWithRedirect(auth, provider);
    const user = result?.user;
    await setCookie(user);
    return { data: user, status: 'ok' };
  } catch (error) {
    return { data: null, error: error.message, status: 'ko' };
  }
}
export async function facebookSignIn() {
  try {
    const provider = new FacebookAuthProvider();
    const result = await signInWithRedirect(auth, provider);
    const user = result?.user;
    await setCookie(user);
    return { data: user, status: 'ok' };
  } catch (error) {
    return { data: null, error: error.message, status: 'ko' };
  }
}
export async function twitterSignIn() {
  try {
    const provider = new TwitterAuthProvider();
    const result = await signInWithRedirect(auth, provider);
    const user = result?.user;
    await setCookie(user);
    return { data: user, status: 'ok' };
  } catch (error) {
    return { data: null, error: error.message, status: 'ko' };
  }
}
export async function signOut() {
  try {
    await setCookie(null);
    await auth.signOut();
    return { status: 'ok' };
  } catch (error) {
    return { data: null, error, status: 'ko' };
  }
}
